<template>
	<div class="forwardingInfo-box">
		<header>
			<div class="left-box header-child">
				<div class="seach-box">
					<el-select v-model="seachName" clearable filterable placeholder="请选择备注名称" @change="seachs">
						<el-option v-for="(i, index) in nameList" :key="index" :label="i.name" :value="i.name"></el-option>
					</el-select>
				</div>
			</div>
			<div class="right-box header-child"><el-button type="primary" size="small" icon="el-icon-plus" @click="openPopup('新增')"></el-button></div>
		</header>
		<ul class="head">
			<li style="width: 5%;min-width: 35px;">序号</li>
			<li style="width: 20%;min-width: 130px;">备注名称</li>
			<li style="width: 11%;min-width: 81.5px;">用户</li>
			<li style="width: 34%;min-width: 200px;">访问地址</li>
			<li style="width: 5%;min-width: 65px;" class="text-center">编辑</li>
		</ul>
		<div class="scroll-box">
			<noData v-show="dataList.length == 0" />
			<vue-scroll v-show="dataList.length != 0">
				<ul class="table-ul table-list-ul" v-for="(i, index) in dataList" :key="index">
					<li style="width: 5%;min-width: 35px;" :title="index + 1">{{ index + 1 }}</li>
					<li style="width: 20%;min-width: 130px;" :title="i.name">{{ i.name | dataDetect }}</li>
					<li style="width: 11%;min-width: 81.5px;" :title="i.userName">{{ i.userName | dataDetect }}</li>
					<li style="width: 34%;min-width: 200px;" :title="i.address" class="address-box">
						<span class="address text-overflow">{{ i.address | dataDetect }}</span>
						<i class="btn el-icon-document-copy pointer" :data-clipboard-text="i.address" title="点击复制链接"></i>
					</li>
					<li style="width: 5%;min-width: 65px;" class="text-center compile">
						<i class="pointer el-icon-edit outline" @click="openPopup('编辑', i)"></i>
						<i class="pointer el-icon-delete delete red" @click="deleteData(i.id, i.userName)"></i>
					</li>
				</ul>
			</vue-scroll>
		</div>
		<!-- 修改弹窗 -->
		<el-dialog :title="popupType" :visible.sync="dialogVisible" custom-class="dialog-revise" :show-close="false" :close-on-click-modal="false">
			<div class="device-revise-box">
				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="5" class="grid-name-box">
						<div class="grid-name">
							<i class="red">*</i>
							企业：
						</div>
					</el-col>
					<el-col :span="8" class="grid-content-box">
						<div class="grid-content">
							<el-select v-model="selectEnterpris" filterable placeholder="请选择企业" @change="enterprisSelect">
								<el-option v-for="(item, index) in enterpriseList" :key="index" :label="item.name" :value="item.children"></el-option>
							</el-select>
						</div>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="5" class="grid-name-box">
						<div class="grid-name">
							<i class="red">*</i>
							用户：
						</div>
					</el-col>
					<el-col :span="8" class="grid-content-box">
						<div class="grid-content">
							<el-select v-model="info.uid" filterable placeholder="请选择用户">
								<el-option
									:title="'项目：' + item.projectName"
									v-for="item in userList"
									:key="item.id"
									:label="item.name + '（' + item.roleName + '）'"
									:value="item.id"
								></el-option>
							</el-select>
						</div>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="5" class="grid-name-box">
						<div class="grid-name">
							<i class="red">*</i>
							访问地址：
						</div>
					</el-col>
					<el-col :span="8" class="grid-content-box">
						<div class="grid-content"><el-input v-model="info.address" placeholder="请输入访问地址"></el-input></div>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="5" class="grid-name-box">
						<div class="grid-name">
							<i class="red">*</i>
							备注名称：
						</div>
					</el-col>
					<el-col :span="8" class="grid-content-box">
						<div class="grid-content"><el-input v-model="info.name" placeholder="请输入备注名称"></el-input></div>
					</el-col>
				</el-row>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button v-show="popupType == '修改配置'" type="primary" @click="modifyDevice">确 定</el-button>
				<el-button v-show="popupType == '新增配置'" type="primary" @click="addConfiguration">新 增</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import ClipboardJS from 'clipboard';
export default {
	name: '',
	data() {
		return {
			list: [],
			seachName: '', //输入的查询备注名称
			nameList: [], //备注名称选择框列表
			enterpriseList: [], // 企业选择列表
			selectEnterpris: null, // 选择的企业
			userList: [], // 用户选择列表(包含公司用户)
			selectUser: null, // 查询选择的用户
			dialogVisible: false, // 修改弹窗是否显示
			popupType: '修改配置', // 弹窗类型
			info: {
				address: '', //访问地址
				// account: null, //FTP账户
				// password: null, //FTP密码
				// ip: null, //转发IP
				// port: null, //端口
				name: null, //备注名称
				uid: null, //用户id
				id: null
			}, // 新增/修改上传参数
			infoParameter: {} // 修改参数
		};
	},
	created() {
		this.getList();
		this.getUser();
	},
	mounted() {
		$(() => {
			var clipboard = new ClipboardJS('.btn');

			clipboard.on('success', e => {
				// // console.log(e);
				this.$message({
					showClose: true,
					message: '复制成功',
					type: 'success'
				});
				e.clearSelection();
			});

			clipboard.on('error', e => {
				// console.error('Action:', e.action);
				// console.error('Trigger:', e.trigger);
				this.$message({
					showClose: true,
					message: '复制失败',
					type: 'error'
				});
			});
		});
	},
	computed: {
		dataList() {
			return this.list.filter(item => {
				if (this.seachName != '') {
					return item.name == this.seachName;
				} else {
					return item;
				}
			});
		}
	},
	methods: {
		// 获取企业、用户列表 gcuser
		getUser() {
			this.axios.get('fdconf/gcuser').then(res => {
				this.enterpriseList = [];
				if (res.status) {
					this.enterpriseList = res.data;
				}
			});
		},
		// 列表数据
		getList() {
			this.axios.get('fdconf/glist').then(res => {
				this.list = [];
				this.nameList = [];
				if (res.status) {
					this.list = res.data;
					res.data.forEach(item => {
						if (item.name != null && item.name != '' && item.name != '-') {
							let num = 0;
							this.nameList.forEach(i => {
								if (i.name == item.name) {
									num += 1;
								}
							});
							if (num == 0) {
								this.nameList.push({
									name: item.name
								});
							}
						}
					});
					this.$parent.nameList = this.nameList;
				}
			});
		},
		// 添加 add
		addConfiguration() {
			for (let key in this.info) {
				if (key != 'id') {
					if (this.info[key] == null || this.info[key] == '') {
						this.$message({
							showClose: true,
							message: '所有参数都不允许为空',
							type: 'warning'
						});
						return;
					}
				}
			}
			this.axios.post('fdconf/add', this.info).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '添加成功',
						type: 'success'
					});
					this.getList();
					this.dialogVisible = false;
				}
			});
		},
		// 修改设备 modify
		modifyDevice() {
			for (let key in this.info) {
				if (key != 'id') {
					if (this.info[key] == null || this.info[key] == '') {
						this.$message({
							showClose: true,
							message: '所有参数都不允许为空',
							type: 'warning'
						});
						return;
					}
				}
			}

			this.axios.post('fdconf/modify', this.info).then(res => {
				if (res.status) {
					this.getList();
					this.$message({
						showClose: true,
						message: '修改成功',
						type: 'success'
					});
					this.dialogVisible = false;
				}
			});
		},
		// 删除 delete
		deleteData(id, name) {
			this.$confirm('是否确定删除<strong>'+ name +'</strong>配置参数？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('fdconf/delete', { id }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								message: '删除成功',
								type: 'success'
							});
							this.getList();
						}
					});
				})
				.catch(() => {});
		},
		// 打开弹窗
		openPopup(val, data) {
			this.reduction();
			if (val == '编辑') {
				this.popupType = '修改配置';
				for (let key in this.info) {
					if (data[key] != undefined && data[key] != '-') {
						if (key != 'userName' && key != 'uid') {
							this.info[key] = data[key];
						} else if (key == 'uid') {
							this.info[key] = Number(data[key]);
						}
					}
				}
				// // console.log(data)
				var len = this.enterpriseList.length;
				// // console.log(this.enterpriseList)
				for (let i = 0; i < len; i++) {
					let item = this.enterpriseList[i];
					if (item.name == data.companyName) {
						this.selectEnterpris = item.name;
						this.enterprisSelect(item.children);
						break;
					}
				}
			} else if (val == '新增') {
				this.popupType = '新增配置';
				this.info.port = 21;
				this.selectEnterpris = null;
			}
			this.dialogVisible = true;
		},

		// 企业选择用户选择列表赋值
		enterprisSelect(val) {
			// // console.log(val);
			this.userList = val;
		},
		// 搜索框选择事件
		seachs(data) {
			// this.seachName = data;
		},
		// 还原info变量
		reduction() {
			for (let key in this.info) {
				if (key == 'address') {
					this.info[key] = '';
				} else {
					this.info[key] = null;
				}
			}
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.forwardingInfo-box {
	width: 100%;
	height: 100%;
	
	::v-deep{
		.el-input__inner {
			height: 32px !important;
			line-height: 32px;
		}
		
		.el-input__icon{
			line-height: 32px;
		}
	}
	

	header {
		width: calc(100% - 30px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 15px 0px 15px;
		margin-bottom: 5px;

		.header-child {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.action {
			background-color: #409eff;
			color: #ffffff;
			border-color: #409eff;
		}

		::v-deep {
			.el-select,
			.el-input,
			.el-input__inner {
				height: 32px;
			}

			.el-input__suffix {
				height: auto;
			}

			.el-dialog__body {
				padding: 0 10px;
			}
		}

		.seach-box {
			margin: 0 0 0px 5px;
		}
	}

	ul {
		width: calc(100% - 24px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 6px 12px;

		li {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 15px;
			margin-left: 5px;

			.tips {
				font-size: 13px;
				font-weight: 500;
			}
		}

		li:first-child {
			margin: 0;
		}

		.password-box {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			input {
				width: calc(100% - 20px) !important;
				border: none;
				background: none;
			}

			.i-active {
				color: #00a5ec;
			}
		}

		.address-box {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.address {
				width: calc(100% - 20px);
			}

			i {
				font-size: 16px;
			}

			i:hover {
				color: #00a5ec;
			}
		}
	}

	.head {
		height: 35px;
		// background-color: #c0c4cc;

		li {
			font-size: 17px;
			font-weight: 600;
			color: #000000;
		}
	}

	.scroll-box {
		height: calc(100% - 130px);

		::v-deep {
			.__view {
				width: 100% !important;
			}
		}
		
		.table-list-ul{
			height: 40px;
			padding-top: 5px;
			padding-bottom: 5px;
		}
		
		.table-list-ul:nth-child(odd) {
			background-color: #ffffff;
		}
		
		.table-list-ul:nth-child(even) {
			background-color: #f2f2f2;
		}
	}

	.grey {
		background-color: #f9f9f9;
	}

	.text-center {
		text-align: center;
	}

	.border-bottom {
		border-bottom: thin solid #00a5ec;
	}

	// 修改弹窗
	.dialog-revise {
		.device-revise-box {
			width: 100%;

			.row-bg {
				margin-bottom: 10px;
				align-items: center;

				::v-deep {
					.el-select,
					.el-input {
						width: 250px;
					}
				}

				.grid-name-box {
					width: 85px;
					text-align: right;
				}

				.grid-content-box {
					width: 220px;

					.grid-content {
						height: 34px;
						line-height: 34px;
					}
				}
			}
		}
	}
}
</style>
