<template>
	<div class="page-box">
		<div class="left-right-box">
			<div class="left-box">
				<p class="box-title">呈现配置</p>
				<forwarding-info ref="forwarding"></forwarding-info>
			</div>
			<div class="right-box">
				<p class="box-title">转发配置</p>
				<header>
					<div class="header-left header-child">
						<el-button-group>
							<el-button size="small" class="but action switchType" @click="switchType($event, 1)">FTP</el-button>
							<el-button size="small" class="but switchType" @click="switchType($event, 2)">HTTP</el-button>
						</el-button-group>
						<div class="seach-box" v-show="pageType == 1">
							<el-select v-model="seachNameF" clearable filterable placeholder="请选择备注名称" @change="seachs()">
								<el-option v-for="(i, index) in fNameList" :key="index" :label="i.name" :value="i.name"></el-option>
							</el-select>
						</div>
						<div class="seach-box" v-show="pageType == 2">
							<el-select v-model="seachNameH" clearable filterable placeholder="请选择备注名称" @change="seachs()">
								<el-option v-for="(i, index) in hNameList" :key="index" :label="i.name" :value="i.name"></el-option>
							</el-select>
						</div>
					</div>
					<div class="header-right header-child"><el-button type="primary" size="small" icon="el-icon-plus" @click="openPopup('新增')"></el-button></div>
				</header>
				<ftp :enterpriseList="enterpriseList" ref="ftp" v-show="pageType == 1"></ftp>
				<http :enterpriseList="enterpriseList" ref="http" v-show="pageType == 2"></http>
			</div>
		</div>
	</div>
</template>

<script>
import forwardingInfo from './child/forwardingInfo.vue';
import ftp from './child/FTP.vue';
import http from './child/HTTP.vue';
export default {
	name: '',
	data() {
		return {
			seachNameF: '', //ftp输入的查询备注名称
			seachNameH: '', //http输入的查询备注名称
			fNameList: [], //ftp备注名称选择框列表
			hNameList: [], //http备注名称选择框列表
			pageType: 1, //数据配置页面类型
			enterpriseList: [], // 企业选择列表
			projectList: [], // 企业选择列表
			checkedList: [] // 设备选择列表
		};
	},
	created() {
		this.getHttpEnterpriseProject();
	},
	mounted() {},
	computed: {},
	methods: {
		// 获取设备列表(老版本)
		getEquipment() {
			this.axios.get('fdconf/gdlist').then(res => {
				this.checkedList = [];
				this.enterpriseList = [];
				this.projectList = [];
				if (res.status) {
					this.checkedList = res.data;
					res.data.forEach(item => {
						if (item.companyName != null && item.companyName != '' && item.companyName != '-') {
							let num = 0;
							this.enterpriseList.forEach(i => {
								if (i.name == item.companyName) {
									num += 1;
								}
							});
							if (num == 0) {
								this.enterpriseList.push({
									name: item.companyName
								});
							}
						}
						if (item.projectName != null && item.projectName != '' && item.projectName != '-') {
							let num = 0;
							this.projectList.forEach(i => {
								if (i.name == item.projectName) {
									num += 1;
								}
							});
							if (num == 0) {
								this.projectList.push({
									name: item.projectName,
									cname: item.companyName
								});
							}
						}
					});
					// console.log(this.enterpriseList)
					$(() => {
						$('.table-ul:even').addClass('grey');
					});
				}
			});
		},
		// 获取http企业项目列表
		getHttpEnterpriseProject(){
			this.enterpriseList = [];
			this.axios.get('fdconf/hp/gpjinfo').then((res)=>{
				this.enterpriseList = [];
				this.enterpriseList = res.data;
			})
		},
		// FTP/HTTP切换
		switchType(e, val) {
			$('.but').removeClass('action');
			$(e.currentTarget).addClass('action');
			this.pageType = val;
		},
		// 搜索框选择事件
		seachs() {
			if (this.pageType == 1) {
				this.$refs.ftp.seachs(this.seachNameF);
			} else {
				this.$refs.http.seachs(this.seachNameH);
			}
		},
		// 打开新增弹窗
		openPopup() {
			if (this.pageType == 1) {
				this.$refs.ftp.openPopup('新增');
			} else {
				this.$refs.http.openPopup('新增');
			}
		}
	},
	components: {
		forwardingInfo,
		ftp,
		http
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.page-box {
	width: 100%;
	height: 100%;

	::v-deep {
		.el-input__inner {
			height: 34px !important;
			line-height: 34px;
		}

		.el-input__icon {
			line-height: 34px;
		}
	}

	.left-right-box {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;

		.box-title {
			font-size: 18px;
			font-weight: 600;
			color: #606266;
			padding: 8px 0 0 10px;
		}

		header {
			width: calc(100% - 30px);
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 15px 0px 15px;
			margin-bottom: 4px;

			.header-child {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}

			.action {
				background-color: #409eff;
				color: #ffffff;
				border-color: #409eff;
			}

			::v-deep {
				.el-select,
				.el-input,
				.el-input__inner {
					width: 200px;
				}

				.el-input__suffix {
					height: auto;
				}

				.el-dialog__body {
					padding: 0 10px;
				}
			}

			.seach-box {
				margin: 0 0 0px 25px;
			}
		}

		.left-box {
			width: calc(40% - 10px);
			height: 100%;
			margin-right: 10px;
			min-width: 552px;
			background-color: #ffffff;
			box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.15);
		}

		.right-box {
			width: calc(60% - 5px);
			height: 100%;
			margin-left: 5px;
			min-width: 566px;
			background-color: #ffffff;
			box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.15);
		}
	}
}
</style>
