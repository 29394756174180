<template>
	<div class="HTTP-box">
		<ul class="head">
			<li style="width: 5%;min-width: 35px;">序号</li>
			<li style="width: 20%;min-width: 130px;">备注名称</li>
			<li style="width: 17%;min-width: 110px;">接口地址</li>
			<li style="width: 12%;min-width: 64px;" class="text-center">发送格式</li>
			<li style="width: 6%;min-width: 34px;" class="text-center">参数</li>
			<li style="width: 16%;min-width: 103.5px;" class="text-center">编辑</li>
		</ul>
		<div class="scroll-box">
			<noData v-show="dataList.length == 0" />
			<vue-scroll v-show="dataList.length != 0">
				<ul class="table-ul table-list-ul" v-for="(i, index) in dataList" :key="index">
					<li style="width: 5%;min-width: 35px;" :title="index + 1">{{ index + 1 }}</li>
					<li style="width: 20%;min-width: 130px;" :title="i.name">{{ i.name | dataDetect }}</li>
					<li style="width: 17%;min-width: 110px;" :title="i.address" class="address-box">
						<span class="address text-overflow">{{ i.address | dataDetect }}</span>
						<i class="btn el-icon-document-copy pointer" :data-clipboard-text="i.address" title="点击复制链接"></i>
					</li>
					<li style="width: 12%;min-width: 64px;" class="text-center">{{ i.type == 1 ? '对象' : '数组' }}</li>
					<li style="width: 6%;min-width: 34px;" class="text-center  compile">
						<el-popover placement="left" width="400" trigger="click" popper-class="parameter-box">
							<div class="parameter-list" v-show="parList.length != 0">
								<vue-scroll>
									<div class="parameter-scroll-box">
										<div class="parameter-cell" v-for="(val, ind) in parList" :key="ind">{{ val.name + '：' + val.pname }}</div>
									</div>
								</vue-scroll>
							</div>
							<noData v-show="parList.length == 0" />
							<i slot="reference" class="pointer el-icon-more outline" title="点击显示参数" @click="setParameter(i.params)"></i>
						</el-popover>
					</li>
					<li style="width: 16%;min-width: 103.5px;" class="text-center compile">
						<el-popover placement="left" width="400" trigger="click" popper-class="equipment-number">
							<div class="equipment-no">
								<vue-scroll>
									<div class="no-scroll-box">
										<div class="no-box" v-for="(val, ind) in projectNameList" :key="ind" :title="val.name">{{ val.name }}</div>
									</div>
								</vue-scroll>
							</div>
							<i slot="reference" class="pointer el-icon-view green" title="点击查看选择的项目" @click="setProjectName(i.pjlist)"></i>
						</el-popover>
						<i class="pointer el-icon-edit outline" title="编辑" @click="openPopup('编辑', i)"></i>
						<i class="pointer el-icon-delete delete red" title="删除" @click="deleteData(i.id, i.name)"></i>
					</li>
				</ul>
			</vue-scroll>
		</div>
		<!-- 修改/新增弹窗 -->
		<el-dialog :title="popupType" :visible.sync="dialogVisible" custom-class="dialog-revise" :show-close="false" :close-on-click-modal="false">
			<el-row type="flex" class="row-box">
				<el-col :span="10" class="col-scroll">
					<vue-scroll>
						<div class="device-revise-box">
							<el-row type="flex" class="row-bg" justify="start">
								<el-col :span="5" class="grid-name-box">
									<div class="grid-name">
										<i class="red">*</i>
										接口地址：
									</div>
								</el-col>
								<el-col :span="10" class="grid-content-box">
									<div class="grid-content"><el-input v-model="info.address" placeholder="请输入HTTP账户"></el-input></div>
								</el-col>
							</el-row>
							<el-row type="flex" class="row-bg" justify="start">
								<el-col :span="5" class="grid-name-box">
									<div class="grid-name">
										<i class="red">*</i>
										发送格式：
									</div>
								</el-col>
								<el-col :span="10" class="grid-content-box">
									<div class="grid-content">
										<el-select v-model="info.type" placeholder="请选择请求方式">
											<el-option v-for="item in askList" :key="item.id" :label="item.name" :value="item.id"></el-option>
										</el-select>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" class="row-bg" justify="start">
								<el-col :span="5" class="grid-name-box">
									<div class="grid-name">
										<i class="red">*</i>
										备注名称：
									</div>
								</el-col>
								<el-col :span="10" class="grid-content-box">
									<div class="grid-content"><el-input v-model="info.name" placeholder="请输入备注名称"></el-input></div>
								</el-col>
							</el-row>
							<el-row type="flex" class="row-bg row-name-x" justify="start">
								<el-col :span="5" class="grid-name-box">
									<div class="grid-name">
										<i class="red">*</i>
										MIT定制转发：
									</div>
								</el-col>
								<el-col :span="10" class="grid-content-box">
									<div class="grid-content">
										<el-tooltip :content="info.sc1 == 1 ? '开' : '关'" placement="top">
											<el-switch v-model="info.sc1" :active-value="1" :inactive-value="0"></el-switch>
										</el-tooltip>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" class="row-bg row-name-x" justify="start">
								<el-col :span="5" class="grid-name-box">
									<div class="grid-name">
										<i class="red">*</i>
										特征值转发：
									</div>
								</el-col>
								<el-col :span="10" class="grid-content-box">
									<div class="grid-content">
										<el-tooltip :content="info.sc2 == 1 ? '开' : '关'" placement="top">
											<el-switch v-model="info.sc2" :active-value="1" :inactive-value="0"></el-switch>
										</el-tooltip>
									</div>
								</el-col>
							</el-row>
							<el-divider content-position="left">参数</el-divider>
							<el-button class="all-fill" size="mini" @click="allFilled">全部填充</el-button>
							<el-row type="flex" class="row-bg" justify="start" v-for="(item, index) in parameter" :key="index">
								<el-col :span="5" class="grid-name-box-p">
									<div class="grid-name">
										<p>
											<!-- <i class="red">*</i> -->
											{{ item.name }}：
											<br />
											<span v-show="item.tips != ''" class="tips-text red">{{ item.tips }}</span>
										</p>
									</div>
								</el-col>
								<el-col :span="10" class="grid-content-box-p">
									<div class="grid-content">
										<input class="parameter" type="text" />
										<el-button class="fill fillButton" size="mini" :data-value="item.defaultValue" title="填充默认值">
											<img src="../../../../assets/images/df2.png" width="28" height="28" />
										</el-button>
									</div>
								</el-col>
							</el-row>
						</div>
					</vue-scroll>
				</el-col>
				<el-col :span="14" class="right-ul-box">
					<div class="check-all-box">
						<!-- <span class="red">*</span> -->
						<el-select v-model="selectEnterprise" filterable placeholder="请选择企业" @change="enterpriseChange">
							<el-option v-for="(item, index) in enterpriseList" :key="index" :label="item.text" :value="item.id"></el-option>
						</el-select>
						<el-select
							v-model="selectProject"
							filterable
							multiple
							collapse-tags
							:disabled="projectSelectList.length == 0"
							placeholder="请选择项目"
							@change="projectChange"
						>
							<el-option v-for="(item, index) in projectSelectList" :key="index" :label="item.text" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="ul-scroll-box">
						<vue-scroll v-show="dList.length != 0">
							<ul>
								<li class="projectLi" v-for="(i, index) in dList" :key="index">
									<!-- <p class="name width-s text-overflow">{{ i.dno }}</p> -->
									<p class="name width-l text-overflow">{{ i.companyName }}</p>
									<p class="name width-x text-overflow">{{ i.text }}</p>
									<span class="el-icon-error pointer deleteIcon" @click="projectDelete(index, i)"></span>
								</li>
							</ul>
						</vue-scroll>
						<noData v-show="dList.length == 0" />
					</div>
				</el-col>
			</el-row>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button v-show="popupType == '修改配置'" type="primary" @click="modifyDevice">确 定</el-button>
				<el-button v-show="popupType == '新增配置'" type="primary" @click="addConfiguration">新 增</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import ClipboardJS from 'clipboard';
export default {
	name: '',
	props: {
		// 企业选择列表
		enterpriseList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			seachName: '', //输入的查询备注名称
			nameList: [], //备注名称选择框列表
			list: [],
			selectEnterprise: '', // 选择的企业id
			selectCompanyName: '', //选择的企业名称
			selectProject: [], // 选择的项目
			projectSelectList: [], //项目选择列表
			checkAll: false, //是否全选
			checked: [], //设备选中上传数据
			askList: [
				{
					id: 1,
					name: '对象'
				},
				{
					id: 2,
					name: '数组'
				}
			], // 发送格式选择列表
			info: {
				address: null, //转发地址
				type: null, //发送格式
				name: null, //备注名称
				pjids: null, // 项目id
				sc1: 0, //定制特征值转发
				sc2: 0, //特征值转发
				id: null
			}, // 新增/修改上传参数
			parameter: [
				{
					name: 'X最大值',
					tips: '',
					defaultValue: 'xMax' //默认值
				},
				{
					name: 'X主频',
					tips: '',
					defaultValue: 'xFrequency' //默认值
				},
				{
					name: 'Y最大值',
					tips: '',
					defaultValue: 'yMax' //默认值
				},
				{
					name: 'Y主频',
					tips: '',
					defaultValue: 'yFrequency' //默认值
				},
				{
					name: 'Z最大值',
					tips: '',
					defaultValue: 'zMax' //默认值
				},
				{
					name: 'Z主频',
					tips: '',
					defaultValue: 'zFrequency' //默认值
				},
				{
					name: '测点名称',
					tips: '',
					defaultValue: 'pointName' //默认值
				},
				{
					name: '设备编号',
					tips: '',
					defaultValue: 'deviceNum' //默认值
				},
				{
					name: '测点地址',
					tips: '',
					defaultValue: 'address' //默认值
				},
				{
					name: '文件名',
					tips: '',
					defaultValue: 'fileName' //默认值
				},
				{
					name: '时间戳',
					tips: '',
					defaultValue: 'timestamp' //默认值
				},
				{
					name: '文件时间',
					tips: 'yyyy-MM-dd HH:mm:ss',
					defaultValue: 'fileDate' //默认值
				},
				{
					name: '文件时间',
					tips: 'yyyyMMddHHmmss',
					defaultValue: 'fileTime' //默认值
				},
				{
					name: 'X轴数据',
					tips: '数组',
					defaultValue: 'xData' //默认值
				},
				{
					name: 'Y轴数据',
					tips: '数组',
					defaultValue: 'yData' //默认值
				},
				{
					name: 'Z轴数据',
					tips: '数组',
					defaultValue: 'zData' //默认值
				},
				{
					name: '数据时间',
					tips: '数组',
					defaultValue: 'dataTime' //默认值
				},
				{
					name: '合成值',
					tips: '',
					defaultValue: 'composeValue' //默认值
				},
				{
					name: '合成主频',
					tips: '',
					defaultValue: 'composeFrequency' //默认值
				},
				{
					name: '操作员',
					tips: '',
					defaultValue: 'operator' //默认值
				},
				{
					name: '项目名称',
					tips: '',
					defaultValue: 'projectName' //默认值
				},
				{
					name: '公司名称',
					tips: '',
					defaultValue: 'companyName' //默认值
				},
				{
					name: '预警值',
					tips: '',
					defaultValue: 'alert' //默认值
				},
				{
					name: '型号参数',
					tips: '',
					defaultValue: 'deviceParam' //默认值
				},
				{
					name: '参数单位',
					tips: '',
					defaultValue: 'paramUnit' //默认值
				},
				{
					name: '负延时',
					tips: '',
					defaultValue: 'negativeDelay' //默认值
				},
				{
					name: '触发电平',
					tips: '',
					defaultValue: 'triggerVoltage' //默认值
				},
				{
					name: '采集时长',
					tips: '',
					defaultValue: 'sampleLength' //默认值
				},
				{
					name: '采样率',
					tips: '',
					defaultValue: 'sampleRate' //默认值
				},
				{
					name: '传感器编号',
					tips: '',
					defaultValue: 'sensorNo' //默认值
				},
				{
					name: '传感器类型',
					tips: '',
					defaultValue: 'sensorType' //默认值
				}
			], // 配置参数
			parList: [], //参数展示列表
			projectNameList: [], // 项目名称展示列表
			projectListData: [], // 展示已选择项目数据
			lastSelection: {}, // 存储用户上次选择的项目，用于对比当前选择的项目
			dialogVisible: false, // 修改弹窗是否显示
			popupType: '修改配置' // 弹窗类型
		};
	},
	created() {
		this.getList();
	},
	mounted() {
		$(() => {
			var clipboard = new ClipboardJS('.btn');

			clipboard.on('success', e => {
				// // console.log(e);
				this.$message({
					showClose: true,
					message: '复制成功',
					type: 'success'
				});
				e.clearSelection();
			});

			clipboard.on('error', e => {
				// console.error('Action:', e.action);
				// console.error('Trigger:', e.trigger);
				this.$message({
					showClose: true,
					message: '复制失败',
					type: 'error'
				});
			});
		});
	},
	computed: {
		// 数据列表
		dataList() {
			return this.list.filter(item => {
				if (this.seachName != '') {
					return item.name == this.seachName;
				} else {
					return item;
				}
			});
		},
		// 选择项目展示列表
		dList() {
			// return this.projectListData.filter(item => {
			// 	if (this.selectEnterprise == '') {
			// 		return item;
			// 	} else {
			// 		if (this.selectEnterprise != '') {
			// 			if (this.selectEnterprise == '全部') {
			// 				return item;
			// 			} else {
			// 				return item.companyName == this.selectEnterprise;
			// 			}
			// 		}
			// 	}
			// });
			return this.projectListData;
		}
	},
	methods: {
		// 列表数据 fp hp
		getList() {
			this.axios.get('fdconf/hp/glist').then(res => {
				this.list = [];
				this.nameList = [];
				if (res.status) {
					this.list = res.data;
					res.data.forEach(item => {
						if (item.name != null && item.name != '' && item.name != '-') {
							let num = 0;
							this.nameList.forEach(i => {
								if (i.name == item.name) {
									num += 1;
								}
							});
							if (num == 0) {
								this.nameList.push({
									name: item.name
								});
							}
						}
					});
					this.$parent.hNameList = this.nameList;
				}
			});
		},
		// 添加 add
		addConfiguration() {
			if (this.checked.length != 0) {
				this.info.pjids = this.checked.toString();
			} else {
				// this.$message({
				// 	showClose: true,
				// 	message: '请选择项目',
				// 	type: 'warning'
				// });
				// return;
			}
			this.info.params = this.getParameter().toString();
			// for (let key in this.info) {
			// 	if (key != 'id') {
			// 		if (this.info[key] == null || this.info[key] == '') {
			// 			this.$message({
			// 				showClose: true,
			// 				message: '所有参数都不允许为空',
			// 				type: 'warning'
			// 			});
			// 			return;
			// 		}
			// 	}
			// }
			setTimeout(() => {
				this.axios.post('fdconf/hp/add', this.info).then(res => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '添加成功',
							type: 'success'
						});
						this.getList();
						this.dialogVisible = false;
					}
				});
			}, 300);
		},
		// 修改项目 modify
		modifyDevice() {
			// console.log(this.checked);
			if (this.checked.length != 0) {
				this.info.pjids = this.checked.toString();
			} else {
				// this.$message({
				// 	showClose: true,
				// 	message: '请选择项目',
				// 	type: 'warning'
				// });
				// return;
			}
			this.info.params = this.getParameter().toString();
			// // console.log(this.info)
			setTimeout(() => {
				// for (let key in this.info) {
				// 	if (key != 'id') {
				// 		if (this.info[key] == null || this.info[key] == '') {
				// 			this.$message({
				// 				showClose: true,
				// 				message: '所有参数都不允许为空',
				// 				type: 'warning'
				// 			});
				// 			return;
				// 		}
				// 	}
				// }
				this.axios.post('fdconf/hp/modify', this.info).then(res => {
					if (res.status) {
						this.getList();
						this.$message({
							showClose: true,
							message: '修改成功',
							type: 'success'
						});
						this.dialogVisible = false;
					}
				});
			}, 300);
		},
		// 删除 delete
		deleteData(id, name) {
			this.$confirm('是否确定删除<strong>' + name + '</strong>配置参数？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('fdconf/hp/delete', { id }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								message: '删除成功',
								type: 'success'
							});
							this.getList();
						}
					});
				})
				.catch(() => {});
		},
		// 删除选择项目
		projectDelete(index, row) {
			// console.log(row);
			// console.log(this.lastSelection)
			this.projectListData.splice(index, 1);
			let ind = this.selectProject.findIndex(item => {
				if (item.id == row.id) {
					return item;
				}
			});
			if (ind != -1) {
				this.selectProject.splice(ind, 1);
			}
			let i = this.lastSelection[row.cid].indexOf(row.id);
			if (i != -1) {
				this.lastSelection[row.cid].splice(i, 1);
			}
			let inds = this.checked.findIndex(el => {
				return el == row.id;
			});
			if (inds != -1) {
				this.checked.splice(inds, 1);
			}
		},
		// 打开弹窗
		openPopup(val, data) {
			// console.log(data);
			this.reduction();
			if (val == '编辑') {
				this.popupType = '修改配置';
				for (let key in this.info) {
					if (data[key] != undefined && data[key] != '-') {
						if (key != 'pjids') {
							this.info[key] = data[key];
						}
					}
				}
				// this.info.pjids = data.pjlist;
				this.enterpriseList.forEach(item => {
					if (item.hasOwnProperty('children')) {
						item.children.forEach(i => {
							if (data.pjlist.includes(i.id)) {
								if (this.lastSelection.hasOwnProperty(item.id)) {
									this.lastSelection[item.id].push(i.id);
								} else {
									this.lastSelection[item.id] = [i.id];
								}
								this.projectListData.push({
									...i,
									companyName: item.text,
									cid: item.id
								});
							}
						});
					}
				});
				// 填写的参数回显
				setTimeout(() => {
					this.setParameter(data.params, true);
				}, 300);
				this.checked = data.pjlist;
			} else if (val == '新增') {
				this.popupType = '新增配置';
				this.info.port = 21;
				this.selectEnterpris = null;
			}
			this.dialogVisible = true;
			// 填充默认值
			setTimeout(() => {
				$('.fillButton').click(e => {
					let val = $(e.currentTarget)
						.prev()
						.val();
					let txt = $(e.currentTarget).attr('data-value');
					$(e.currentTarget)
						.prev()
						.val(txt);
				});
			});
		},
		// 全部填充默认值
		allFilled() {
			$('.fillButton').trigger('click');
		},
		// 搜索框选择事件
		seachs(data) {
			this.seachName = data;
		},
		// 还原info变量 并还原弹窗原始状态
		reduction() {
			this.selectEnterprise = '';
			this.selectProject = [];
			this.projectSelectList = [];
			this.checked = [];
			this.projectListData = [];
			this.lastSelection = {};
			$('.parameter').val('');
			for (let key in this.info) {
				if (key == 'address') {
					this.info[key] = '';
				} else {
					this.info[key] = null;
				}
			}
		},
		// 根据id获取项目名称
		setProjectName(ids) {
			this.projectNameList = [];
			this.enterpriseList.forEach(item => {
				if (item.hasOwnProperty('children')) {
					item.children.forEach(i => {
						if (ids.includes(i.id)) {
							this.projectNameList.push({
								name: i.text,
								id: i.id
							});
						}
					});
				}
			});
		},
		// 获取配置里面的参数项数据
		getParameter() {
			var arr = [];
			var obj = $('.parameter');
			var len = obj.length;
			for (let i = 0; i < len; i++) {
				let val = obj.eq(i).val();
				arr.push(val);
			}
			// // console.log(arr);
			return arr;
		},
		// 根据返回的数据获取填了那些参数配置
		setParameter(data, write = false) {
			// write : 是否将获取到的参数内容回显
			// console.log(data);
			var arr = data.split(',');
			var len = arr.length;
			var qlen = this.parList.length;
			var obj = $('.parameter');
			// console.log(arr);
			for (let i = 0; i < len; i++) {
				if (write) {
					obj.eq(i).val(arr[i]);
				} else {
					if (arr[i] != '') {
						let obj = {
							name: this.parameter[i].name,
							pname: arr[i]
						};
						// this.parList.push({
						// 	name: this.parameter[i].name,
						// 	pname: arr[i]
						// });
						this.$set(this.parList, i, obj);
					}
				}
			}
			// console.log(this.parList);
		},
		// 企业下拉框选择
		enterpriseChange(val) {
			this.selectProject = [];
			this.projectSelectList = [];
			try {
				this.enterpriseList.forEach(item => {
					if (item.id == val) {
						this.selectCompanyName = item.text;
						this.projectSelectList = item.children;
						throw '';
					}
				});
			} catch (e) {
				//TODO handle the exception
			}
			if (this.lastSelection.hasOwnProperty(this.selectEnterprise)) {
				this.selectProject = this.lastSelection[this.selectEnterprise];
			}
		},
		// 项目下拉框选择
		projectChange(val) {
			// // console.log(val);
			let projectId = []; //上次选的项目和当前选择项目出现差异的id数组
			let existence = []; //需要移除的项目index
			let remove = this.projectListData.length > 0;

			this.checked = [];

			if (!this.lastSelection.hasOwnProperty(this.selectEnterprise)) {
				this.lastSelection[this.selectEnterprise] = val;
			} else {
				this.lastSelection[this.selectEnterprise].forEach(item => {
					if (!val.includes(item)) {
						projectId.push(item);
					}
				});
				this.lastSelection[this.selectEnterprise] = val;
			}
			val.forEach(item => {
				let push = true;
				try {
					this.projectListData.forEach((v, index) => {
						if (v.id == item) {
							push = false;
							throw '';
						}
					});
				} catch (e) {
					//TODO handle the exception
				}

				try {
					this.projectSelectList.forEach(el => {
						if (el.id == item && push) {
							this.projectListData.push({
								...el,
								companyName: this.selectCompanyName,
								cid: this.selectEnterprise
							});
							throw '';
						}
					});
				} catch (e) {
					//TODO handle the exception
				}
			});

			try {
				this.projectListData.forEach((v, index) => {
					if (projectId.includes(v.id)) {
						existence.push(index);
						throw '';
					}
				});
			} catch (e) {
				//TODO handle the exception
			}

			// // console.log(existence)
			existence.forEach(i => {
				this.projectListData.splice(i, 1);
			});

			this.projectListData.forEach(i => {
				this.checked.push(i.id);
			});
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.HTTP-box {
	// width: 100%;
	height: 100%;

	::v-deep {
		.el-input__inner {
			height: 32px !important;
			line-height: 32px;
		}

		.el-input__icon {
			line-height: 32px;
		}
	}

	.head,
	.table-ul {
		width: calc(100% - 24px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 6px 12px;

		li {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 15px;
			margin-left: 5px;

			.tips {
				font-size: 13px;
				font-weight: 500;
			}
		}

		li:first-child {
			margin: 0;
		}

		.password-box {
			width: 100%;

			::v-deep {
				.el-tooltip {
					background: none;
					border: none;
					padding: 0;
					font-size: 16px;
					font-weight: 700;
					color: #000000;
				}
			}
		}
	}

	.head {
		height: 35px;

		li {
			font-size: 17px;
			font-weight: 600;
		}
	}

	.scroll-box {
		width: 100%;
		height: calc(100% - 130px);

		::v-deep {
			.__view {
				width: 100% !important;
			}
		}

		.table-list-ul {
			height: 40px;
			padding-top: 5px;
			padding-bottom: 5px;
		}

		.table-list-ul:nth-child(odd) {
			background-color: #ffffff;
		}

		.table-list-ul:nth-child(even) {
			background-color: #f2f2f2;
		}

		.address-box {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.address {
				width: calc(100% - 20px);
			}

			i {
				font-size: 16px;
			}

			i:hover {
				color: #00a5ec;
			}
		}
	}

	.grey {
		background-color: #f9f9f9;
	}

	.text-center {
		text-align: center;
	}

	.border-bottom {
		border-bottom: thin solid #00a5ec;
	}

	// 修改弹窗
	::v-deep {
		.el-dialog__body {
			padding: 10px 15px 10px 15px;
		}

		.el-dialog__body {
			height: calc(100% - 134px);
		}

		.el-dialog__footer {
			padding: 10px 20px;
		}

		.row-box {
			height: 100%;
		}

		.dialog-revise {
			min-width: 1100px;
			height: 500px;

			.col-scroll {
				height: 100%;
			}

			.device-revise-box {
				width: 90%;

				// 全部填充按钮
				.all-fill {
					padding: 7px 10px;
					color: #00a6f9;
					font-size: 12px;
					// font-style: italic;
					border: thin solid #d8d8d8;
					background-color: #ffffff;
					float: right;
					margin: -10px 0 15px 0;
				}

				.row-bg {
					margin-bottom: 10px;
					align-items: center;
					clear: both;

					.grid-name-box {
						width: 85px;
						// text-align: right;
					}

					.grid-name-box-p {
						width: 155px;
						text-align: left;
					}

					.grid-content-box {
						width: 220px;

						.grid-content {
							height: 30px;
							line-height: 30px;

							.el-select,
							.el-input,
							.el-input__inner {
								width: 220px !important;
								height: 30px;
							}
						}
					}

					.grid-content-box-p {
						width: calc(100% - 165px);

						.grid-content {
							height: 30px;
							line-height: 30px;
							display: flex;
							justify-content: space-between;
							align-items: center;

							input {
								width: 150px;
								height: 30px;
								padding: 0 10px;
								line-height: 30px;
								border: 1px solid #e5e5e5;
							}

							.fill {
								width: 28px;
								height: 28px;
								padding: 0;
								line-height: 28px;
								text-align: center;
								border-radius: 100%;
								color: #00a6f9;
								font-size: 12px;
								font-style: italic;
								// border: thin solid #d8d8d8;
								border: none;
								background-color: #ffffff;
							}
						}
					}

					.tips-text {
						font-size: 12px;
					}
				}

				.row-name-x {
					.grid-name-box {
						width: 104px;
					}

					.grid-content-box {
						width: 195px;
					}
				}
			}
		}
	}
	// 弹窗输入框样式更改
	.grid-content-box {
		.grid-content {
			::v-deep {
				.el-select,
				.el-input,
				.el-input__inner {
					width: 220px !important;
					height: 30px;
				}
			}
		}
	}

	.grid-content-box-p {
		.grid-content {
			::v-deep {
				.el-select,
				.el-input,
				.el-input__inner {
					width: 150px !important;
					height: 30px;
				}
			}
		}
	}
	// 弹窗右边选择盒子
	.right-ul-box {
		height: 100%;

		::v-deep {
			.el-checkbox-button {
				width: 100%;
			}

			.el-checkbox-button__inner {
				// width: 100%;
				padding: 8px 10px;
				border-radius: 4px;
				display: flex;
				justify-content: space-between;
				border: 1px solid #dcdfe6 !important;
			}

			.el-checkbox-button.is-checked .el-checkbox-button__inner {
				box-shadow: unset;
			}
		}
		.check-all-box {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 6px;

			::v-deep {
				.el-select,
				.el-input__inner {
					width: 200px;
				}

				.el-select {
					margin-right: 20px;

					.el-select__tags {
						flex-wrap: nowrap;

						.el-tag {
							max-width: calc(100% - 50px);
						}
					}
				}

				.el-checkbox-button,
				.el-checkbox-button__inner {
					width: 46px !important;
					font-size: 12px;
				}
			}
		}

		.ul-scroll-box {
			width: 100%;
			height: calc(100% - 34px);

			::v-deep {
				.__panel {
					width: 100%;

					.__view {
						width: 100% !important;
					}
				}
			}

			ul {
				width: 100%;
				display: flex;
				flex-direction: column;

				.projectLi {
					width: calc(100% - 18px);
					border: 1px solid #e4e7ed;
					border-radius: 4px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 6px;
					padding: 5px 8px;

					.name {
						text-align: left;
					}

					.width-l {
						width: calc(55% - 10px);
					}

					.width-x {
						width: calc(45% - 10px);
					}

					.deleteIcon {
						font-size: 18px;
					}
				}
			}

			.is-checked {
				::v-deep {
					.el-checkbox-button__inner {
						color: #409eff;
						background-color: #fff;
						border: 1px solid #dcdfe6;
					}
				}

				i {
					opacity: 1;
					color: #409eff;
				}
			}
		}
	}
}
</style>

<style lang="scss">
.equipment-number,
.parameter-box {
	.equipment-no,
	.parameter-list {
		width: 100%;
		height: 200px;

		.__panel {
			width: 100%;

			.__view {
				width: 100% !important;
			}
		}

		.parameter-scroll-box {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;

			.parameter-cell {
				padding: 4px 6px;
				border: thin solid #e4e7ed;
				border-radius: 4px;
				margin: 0 3px 5px 2px;
			}
		}

		.no-scroll-box {
			width: 100%;
			// display: flex;
			// justify-content: flex-start;
			// flex-wrap: wrap;

			.no-box {
				width: calc(100% - 14px);
				padding: 4px 6px;
				border: thin solid #e4e7ed;
				border-radius: 4px;
				margin: 0 0 5px 0;
			}
		}
	}
}
</style>
